<template>
  <div class="luckdraw_list">
    <!-- <img class="logo" src="./../assets/img/logo.png" alt="">
      <div class="luckdraw_box">
          <img class="daidai2" src="./../assets/img/daidai2.png" alt="">
          <p></p>
    </div>-->
    <div class="luckdraw_box">
      <div class="luckdraw_header">
        <p>抽奖记录</p>
        <span>Lucky Draw</span>
      </div>
      <div class="list_box">
        <div
          v-for="(item,index) in prize_list"
          :key="index"
          class="item_box"
        >
          <div class="img_box">
            <img src="../../assets/pic.png" alt />
          </div>
          <div class="prize_info">
            <p class="prize_name">{{item.prize_name}}</p>
            <span class="add_time">参与时间：{{timestampToTime(item.add_time)}}</span>
          </div>
          <!-- <div class="flxe">
            <template v-if="item.is_coupon==4"> -->
                <!-- 实物物品状态 -->
                <!-- <div class="site" v-if="item.status==1" @click="setCity(item)">填写收货地址</div>
                <div class="btn_status btn_status2" v-if="item.status == 2" @click="godetails(item)">查看详情</div>
                <div class="btn_status" v-if="item.status==2" @click.stop="click_wuliu(item.id)">查看物流</div>
            </template>
            <template v-else-if="item.is_coupon==1">
                <div class="btn_status" v-if="item.status == 1" @click="godetails(item)">未领取</div>
                <div class="btn_status btn_status2" v-if="item.status == 2">已领取</div>
                <div class="btn_status btn_status2" v-if="item.status==2" @click="godetails(item)">查看详情</div>
            </template>
            <template v-else>
                <div class="btn_status btn_status2" v-if="item.is_coupon!=3" @click="godetails(item)">查看详情</div>
            </template>
          </div> -->
        </div>
      </div>

      <div class="go_luckdrawbtn" @click="go_luckdrawbtn">
        <span class="btn_one_ch">返回</span>
        <span class="btn_one_en">Back</span>
      </div>
    </div>
    <!-- <div :class="show_page_add?'input_adddress inputOpen':'input_adddress'">
        <div class="popup_box popup_box2">
            <div class="form">
                <div class="i_a_tit">填写收货地址</div>
                <div class="formItem">
                    <div class="label">
                      <div class="zh">姓名</div>
                      <div class="en">Name</div>
                    </div>
                    <input type="text" class="value" placeholder="请输入收件人姓名" v-model="params.send_username" />
                </div>
                <div class="formItem">
                    <div class="label">
                      <div class="zh">手机</div>
                      <div class="en">Tel</div>
                    </div>
                    <input type="tel" class="value" placeholder="请输入收件人手机号码" maxlength="11" v-model="params.send_phone" />
                </div>
                <div class="formItem">
                  <div class="label">
                    <div class="zh">城市</div>
                        <div class="en">City</div>
                    </div>
                    <van-cell class="value" is-link @click="show_address = true">
                      <span v-if="!params.send_province">所在省市区</span>
                    <span style="line-height:20px" v-else>{{params.send_province}}{{params.send_city}}{{params.send_area}}</span>
                      
                    </van-cell>
                </div>
                <div class="formItem areatext">
                    <div class="label">
                        <div class="zh">详细地址</div>
                        <div class="en">Address</div>
                      </div>
                    <textarea class="value" cols="30" rows="10" placeholder="请填写详细地址" v-model="params.send_addr"></textarea>
                </div>
                <div class="i_a_btn" @click="submit">
                  <span class="btn_one_ch">领取</span> 
                  <span class="btn_one_en">Claim</span>
                </div>
            </div>
        </div>
        <img class="close_popup" @click="show_page_add=false" src="../../assets/img/close_img.png" alt="">
    </div> -->
    <!-- <div class="wuliu_popup" v-if="show_wuliu">
          <div class="wuliu_box">
              <div class="wuliu_tit" v-if="wuliu_list">物流详情</div>
              <div class="wuliu_express_number" v-if="wuliu_list">
                 <span>{{wuliu_list.express.mail_name}}</span>
                  <span>物流信息：{{wuliu_list.express.express_number}}</span>
              </div>
              <div class="wuliu_b_list" v-if="wuliu_list">
                  <van-steps direction="vertical" :active="0" active-color="#06C0F6">
                      <van-step v-for="(item, index) in wuliu_list.express_msg" :key="index" class="wuliu_b_item">
                          <p class="item_content">{{item.context}}</p>
                          <p class="item_time">{{item.time}}</p>
                      </van-step>
                  </van-steps>
              </div>
              <div v-if="!(wuliu_list) " style="width: 100%;text-align: center">
                  <img src="../../assets/img/no_wuliu.png" style="width: 100%" alt="">
              </div>
          </div>
          <img src="../../assets/img/close_img.png" class="close_img" alt="" @click="show_wuliu = false">
      </div> -->
    <!-- <van-popup v-model="show_address" position="bottom">
      <van-area
        :area-list="areaList"
        :value="value"
        @cancel="show_address = false"
        @confirm="click_address"/>
    </van-popup> -->
  </div>
</template>

<script>
var qs = require("qs");
import AreaList from "../../assets/area.js";
export default {
  name: "index",
  props: {
    msg: String
  },
  data() {
    return {
      wuliu_list:'',
      show_wuliu:false,
        show_address:false,
        value:"",
        show_page_add:false,
        areaList:AreaList,
        params:{
          ut:"",
          id:"",
          send_username:"",
          send_phone:"",
          send_province:"",
          send_city:"",
          send_area:"",
          send_addr:""
      },
      prize_list: "",
    };
  },
  created() {
    this.getprize_list();
    // if(localStorage.getItem("addres")){
    //   this.getUserAddress();
    // }
  },
  methods: {
      // 默认地址
    // getUserAddress(){
    //   // 请求用户地址
    //   this.$axios({
    //     method: "post",
    //     url: this.$url + "/Api/User/getUserAddress",
    //     data: qs.stringify({
    //       ut: localStorage.getItem("ut"),
    //     }),
    //   }).then((res) => {
    //     if (res.data.code == 1) {
    //       var getUserAddress = res.data.data
    //       localStorage.setItem("getUserAddress_diamond",JSON.stringify(getUserAddress.diamond));
    //       localStorage.setItem("getUserAddress_normal", JSON.stringify(res.data.data.normal));
    //       var getUserAddress = JSON.parse(localStorage.getItem("getUserAddress_normal"))
    //       console.log('getUserAddress=',getUserAddress.send_username)
    //       this.params.send_username = getUserAddress.send_username || ''
    //       this.params.send_phone = getUserAddress.send_phone || ''
    //       this.params.send_province = getUserAddress.send_province || ''
    //       this.params.send_city = getUserAddress.send_city || ''
    //       this.params.send_area = getUserAddress.send_area || ''
    //       this.params.send_addr = getUserAddress.send_addr || ''
    //     }
    //   });
    // },
    // click_wuliu(id){
    //       var data = qs.stringify({
    //           ut: localStorage.getItem("ut"),
    //           userid: localStorage.getItem("userid"),
    //           id:id,
    //           show_type:3
    //       });
    //       this.$axios({
    //           method: "post",
    //           url: this.$url + "/Api/Wuliu/getWuliu",
    //           data: data
    //       }).then(res => {
    //           console.log("order_list", res);
    //           this.show_wuliu = true;
    //           if(res.data.code == 1){
    //               this.wuliu_list = res.data.data
    //           }else{
    //               this.wuliu_list = ''

    //           }

    //       });
    //   },
    //   setCity(item){
    //       this.show_page_add = true;
    //       this.params.id = item.id;
    //   },
    //   click_address(e) {
    //   console.log(e);
    //   this.params.send_province = e[0].name;
    //   this.params.send_city = e[1].name;
    //   this.params.send_area = e[2].name;
    //   //this.params.code = e[2].code;
    //   //this.params.value = e[2].code;
    //   this.show_address = false;
    // },
      //领取实物
    // submit(){
    //     if(!this.params.send_username){
    //         this.$toast({
    //             mask: true,
    //             message: "请输入姓名",
    //             zIndex:999999
    //         });
    //         return ;
    //     }
    //     if(!/^1[3456789]\d{9}$/.test(this.params.send_phone)){
    //         this.$toast({
    //             mask: true,
    //             message: "请输入正确的手机号",
    //             zIndex:999999
    //         });
    //         return ;
    //     }
    //     if(!this.params.send_province){
    //         this.$toast({
    //             mask: true,
    //             message: "请选择省市区",
    //             zIndex:999999
    //         });
    //         return ;
    //     }
    //     if(!this.params.send_addr){
    //         this.$toast({
    //             mask: true,
    //             message: "请输入详细地址",
    //             zIndex:999999
    //         });
    //         return ;
    //     }
    //   console.log(JSON.stringify({
    //         ...this.params,
    //         ut:localStorage.getItem("ut")
    //     }));
    //   this.$axios({
    //     method: "post",
    //     url: this.$url + "/Api/Score/add_address",
    //     data: qs.stringify({
    //         ...this.params,
    //         ut:localStorage.getItem("ut")
    //     })
    //   }).then(res => {
    //     this.$toast({
    //         message: res.data.msg,
    //         zIndex:999999
    //     });
    //     if(res.data.code==1){
    //         this.show_page_add = false;
    //         this.$router.push({
    //             path:"/luckdrawEntity",
    //             query:{
    //                 ...this.params,
    //                 ...this.actives
    //             }
    //         })
    //     }
    //   });
    // },
    // get_juan(item){
    //   console.log(item)
    //   var that = this
    //   var data = qs.stringify({
    //     ut: localStorage.getItem("ut"),
    //     userid: localStorage.getItem("userid"),
    //     prize_id:item.prize_id,
    //     id:item.id,
    //   });
    //   this.$axios({
    //     method: "post",
    //     url: this.$url + "/Api/Score/save_info",
    //     data: data
    //   }).then(res => {
    //     console.log(res);
    //     that.$toast({
    //       mask: true,
    //       message: '领取成功',
    //       zIndex:999999
    //     });
    //     that.getprize_list()
    //     // this.userinfo = res.data.data;
    //   });
    // },
    // godetails(item) {
    //     localStorage.setItem("prize", JSON.stringify(item));
    //     if(item.is_coupon==1){
    //         this.$router.push({ path: "/luckdrawdetails" });
    //     }else{
    //         this.$router.push({ path: "/luckdrawEntity",query:{id:item.id}});
    //     }
    // },
    go_luckdrawbtn() {
      this.$router.push({ path: "/H5_luckdraw" });
    },
    getprize_list() {
      // var data = qs.stringify({
      //   ut: localStorage.getItem("ut"),
      //   userid: localStorage.getItem("userid"),
      // });
      // this.$axios({
      //   method: "post",
      //   url: this.$url + "/Api/Score/prize_list",
      //   data: data
      // }).then(res => {
        // console.log(res);
      this.prize_list = JSON.parse(localStorage.getItem('picInfo'));
      // });
    },
    timestampToTime(timestamp) {
      timestamp = timestamp ? timestamp : null;
      let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    }
  },
  filters: {
    filter_length(e) {
      console.log(this.show123)
      return e.substring(0, e.length - 3);
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.flxe{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.flxe .site{
    width: auto;
    height: 58px;
    padding: 0 10px;
    background: rgba(107, 218, 250, 1);
    border-radius: 29px;
    font-size: 24px;
    
    font-weight: normal;
    color: rgba(254, 254, 254, 1);
    text-align: center;
    line-height: 58px;
    margin-top: 20px;
}
.form{
    width: 100%;
    
}
.msg{
        color: #F9121A;
        font-size: 22px;
        line-height: 33px;
        padding:  0 29px;
    }
.close_popup{
  width: 74px;
  height: 74px;
  margin-top: 20px;
}
.i_a_btn {
  width: 100%;
  height: 78px;
  background: #06c0f6;
  opacity: 1;
  border-radius: 0.13333rem;
  border: none;
  font-size: 30px;
  
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  margin-top: 31px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
}
.zh1{
    font-size: 28px;
  }
  .en1{
    font-size: 18px;
  }
.formItem{
    display: flex;
    height: 90px;
    background: #F3F3F3;
    border-radius: 8px;
    overflow: hidden;
    padding: 0 30px;
    margin-bottom:16px ;
    
    
    
}
.value{
        flex: auto;
        border:none;
        outline: none;
        background: none;
        text-align: right;
        font-size: 26px;
        color: #171A1D;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
.label{
        width: 170px;
        color: rgba(23, 26, 29, 1);
        font-size: 26px;
        font-weight: 400;
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }

    .zh{
          font-weight: 600;
          font-size: 26px;
        }
        .en{
          font-weight: 400;
          font-size: 18px;
        }
.areatext{
    height:168px ;
    
}
.areatext .value{
        line-height: 44px;
        padding: 25px 0;
        box-sizing: border-box;
        resize: none;
    }
.popup_box {
  width: 612px;
  /* height: 367px; */
  background: rgba(255, 255, 255, 1);
  border-radius: 16px;
  box-sizing: border-box;
  padding: 31px 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.popup_box2 {
  background: #fff;
}
.input_adddress {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.2s;
  transform: scale(0);
}
.input_adddress.inputOpen{
    transform: scale(1);
}
.luckdraw_list {
  width: 100%;
  height: 100vh;
  background: #a4e5ff;
  box-sizing: border-box;
  padding: 73px 30px 0;
}
.luckdraw_box {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 30px 30px 0px 0px;
  box-sizing: border-box;
  padding: 40px 40px 0;
  position: relative;
}
.luckdraw_header {
  text-align: center;
  box-sizing: border-box;
}
.luckdraw_header p {
  font-size: 30px;
  
  font-weight: normal;
  color: rgba(44, 45, 53, 1);
}
.luckdraw_header span {
  font-size: 18px;
  
  font-weight: normal;
  color: rgba(44, 45, 53, 1);
  display: inline-block;
  padding-bottom: 40px;
  width: 100%;
  border-bottom: 1px solid rgba(236, 236, 236, 1);
}
.list_box {
  width: 100%;
  height: 74%;
  overflow-y: scroll;
}
.item_box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid rgba(236, 236, 236, 1);
  box-sizing: border-box;
  padding: 47px 0;
}
.item_box .img_box {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(225, 238, 246, 1);
  border-radius: 50%;
  overflow: hidden;
  margin-right: 22px;
}
.img_box img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.prize_info {
  width: 350px;
}
.prize_name {
  width: 330px;
  font-size: 30px;
  
  font-weight: normal;
  color: rgba(44, 45, 53, 1);
  display: inline-block;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow:ellipsis;
}
.add_time {
  display: inline-block;
  font-size: 24px;
  
  font-weight: normal;
  color: rgba(170, 170, 170, 1);
}
.btn_status {
  width: 130px;
  height: 58px;
  background: rgba(107, 218, 250, 1);
  border-radius: 29px;
  font-size: 26px;
  
  font-weight: normal;
  color: rgba(254, 254, 254, 1);
  text-align: center;
  line-height: 58px;
}
.btn_status2{
    background:rgba(234,234,234,1);
    color:rgba(170,170,170,1);
    margin-top: 10px;
}
.go_luckdrawbtn {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 580px;
  height: 90px;
  background: rgba(107, 218, 250, 1);
  border-radius:8px;
  font-size: 32px;
  
  font-weight: normal;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  margin: 0px auto 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
  